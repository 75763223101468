.tag {

  font-weight: bold;
  border-radius: var(--border-radius-small);
  font-size: var(--font-size-100);
  display: inline-block;
  padding: 0 .4em;
  cursor: default;

  &.gray {
    background-color: var(--color-gray-200);
    color: var(--text-gray-600);
  }
}
