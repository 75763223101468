[data-component="LoanSection"] {

  position: relative;

  > .inner-wrapper {

    padding-bottom: 1.5rem;
    padding-top: 1.5rem;

    transition: padding .3s ease;

    [data-component="feedback-bar"] {
      margin-bottom: var(--spacing-300);
    }

    > header {

      > h3 {
        font-family: "Source Serif Pro", serif;
        font-weight: 700;
        margin-bottom: 0.3em;
      }
    }

    > header > p, .section-intro {

      color: var(--color-gray-600);
      font-size: var(--font-size-400);
      margin-bottom: 1rem;
      max-width:  var(--max-line-length);
    }
  }

  &::before {
    content: '';

    position: absolute;
    left: -16px;
    top: 0;
    bottom: unset;

    height: 0;

    transition: height .4s ease-in;
  }

  &[data-complete="true"] {

    &:not(:last-of-type) {
      margin-bottom: 0;
    }

    &::before {
      height: 100%;
      z-index: 1;
      background-color: var(--color-success-600);
      width: 2px;
    }
  }

  .section-foot-note {

    font-weight: var(--font-size-300);
    font-weight: normal;
    color: var(--color-gray-400);

    max-width: var(--max-line-length);

    @media(min-width: 640px) {
      column-count: 2;
    }
  }
}
