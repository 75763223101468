[data-component="Pincode"] {

  display: grid;
  grid-template-columns: repeat(4, 3rem);
  gap: 10px;

  .input-wrapper > input {
    text-align: center;
  }
}
