[data-component="CardOffer"] {

  border: 1px solid #ddd;
  padding: var(--spacing-300) var(--spacing-200);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  border-radius: var(--border-radius-medium);
  background-color: white;
  box-shadow: var(--box-shadow-sharp);

  &[data-loading="true"] {
    /* opacity: 0; */
  }

  &[data-loading="false"] {
    opacity: 1;
    transition: opacity .3s ease;
  }

  &[data-selected="true"] {
    border: 3px solid var(--color-primary-400);
  }

  &[data-order="0"] { order: 0; }
  &[data-order="1"] { order: 1; }
  &[data-order="2"] { order: 2; }
  &[data-order="-1"] { order: 666; }

  > h3 {
    @apply text-500 leading-[95%];
    font-weight: 700;
  }

  .image-container {
    > img {
      object-fit: cover;
      width: 100%;
    }
  }

  .card-bullets {
    > li {
      display: flex;
      justify-content: space-between;
      line-height: 1.8;

      .amount {
        color: var(--color-primary-500);
        font-weight: 700;
      }

      .percentage {
        @apply text-400 font-bold text-heading;
      }
    }
  }

  .card-explanation {

    flex: 1;

    > h4 {
      @apply text-300 leading-[95%];
      font-weight: 700;
      margin: var(--heading-margin-medium);
      text-transform: uppercase;
    }
  }

  .loading {
    height: 2rem;
    background-color: var(--color-gray-50);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
  }

  .card-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    gap: 1rem;
  }
}
