[data-component="GuarantorsList"] {

  > li {

    display: flex;
    flex-direction: row;
    align-items: center;

    gap: var(--spacing-200);
    padding: var(--spacing-200) 0;

    & + li {
      border-top: 1px solid var(--color-gray-50);
    }

    .content-wrapper {

      flex: 1;

      display: flex;
      flex-direction: column;

      gap: var(--spacing-100);

      .inner-wrapper {
        display: flex;
        flex-direction: row;

        gap: 0 var(--spacing-200);

        @media(max-width: 550px) {
          > *:not(.ssn) {
            display: none;
          }
        }
      }
    }
  }
}
