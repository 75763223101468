[data-component="SelectProvider"] {

  width: 100%;
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid var(--color-gray-50);

  > button {

    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

    padding: 1rem;
    cursor: pointer;
    transition: background .2s ease-out;

    @apply text-gray-900;

    .erp-name {
      flex: 1;
    }

    &:hover {
      background-color: var(--color-gray-50);
    }

    & + button {
      border-top: 1px solid var(--color-gray-50);
    }
  }
}
