/* Base theme fonts */
@font-face { /* Source Serif Pro 700 latin-ext */
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSKqxKcsdrOPbQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face { /* Source Serif Pro 700 latin */
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourceserifpro/v15/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSyqxKcsdrM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face { /* Source Sans Pro 300 latin-ext */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwmRdu3cOWxy40.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face { /* Source Sans Pro 300 latin */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zwlxdu3cOWxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face { /* Source Sans Pro 400 latin-ext */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lujVj9_mf.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face { /* Source Sans Pro 400 latin */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face { /* Source Sans Pro 700 latin-ext */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRdu3cOWxy40.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face { /* Source Sans Pro 700 latin */
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu3cOWxw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face { /* Source Code Pro 400 latin-ext */
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v21/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMdrSlcZZJmOpwVS.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face { /* Source Code Pro 400 latin */
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sourcecodepro/v21/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQtMlrSlcZZJmOpw.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@media all { /* design tokens */
  :root { /* global tokens */

    /* breakpoints */
    --breakpoint-xs: 390px;
    --breakpoint-sm: 640px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 1024px;
    --breakpoint-xl: 1280px;
    --breakpoint-2l: 1536px;

    /* colors */
    --color-white: #ffffff;
    --color-black: #000000;
    --color-gray-25: #fafafa;
    --color-gray-50: #f2f2f2;
    --color-gray-100: #e6e6e6;
    --color-gray-200: #cccccc;
    --color-gray-300: #b3b3b3;
    --color-gray-400: #999999;
    --color-gray-500: #808080;
    --color-gray-600: #666666;
    --color-gray-700: #4d4d4d;
    --color-gray-800: #333333;
    --color-gray-900: #1a1a1a;

    /* sizes & spacing */
    --base-size: 4px;

    /* fonts */
    --type-scale: 1.125; /* Major second */
    --font-base-size: 16px;
    --font-family-normal: "Source Sans Pro", sans-serif;
    --font-family-bold: "Source Sans Pro", sans-serif;
    --font-family-display: "Source Serif Pro", sans-serif;
    --font-family-mono: "Source Code Pro", sans-serif;
    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-weight-display: 700;
    --font-weight-mono: 400;

    /* borders */
    --border-width-small: 1px;
    --border-width-medium: 2px;
    --border-width-large: 4px;

    /* border radiuses */
    --border-radius-small: var(--size-50);
    --border-radius-medium: var(--size-100);
    --border-radius-large: var(--size-400);

    /* shadows */
    --drop-shadow-large:
        0 0 1px rgba(0,0,0,0.05),
        0 4px 4px -2px rgba(0,0,0,0.1),
        0 8px 8px -4px rgba(0,0,0,0.1),
        0 16px 16px -8px rgba(0,0,0,0.1),
        0 32px 32px -8px rgba(0,0,0,0.1);
    --drop-shadow-medium:
        0 0 1px rgba(0,0,0,0.05),
        0 4px 4px -2px rgba(0,0,0,0.1),
        0 8px 8px -4px rgba(0,0,0,0.1),
        0 16px 16px -8px rgba(0,0,0,0.1);
    --drop-shadow-small:
        0 0 1px rgba(0,0,0,0.05),
        0 2px 2px -1px rgba(0,0,0,0.1),
        0 4px 4px -2px rgba(0,0,0,0.1);
    --text-shadow-normal: none;
    --focus-outline: 0 0 0 4px var(--color-focus);

    /* levels */
    --level-900: 500;
    --level-800: 400;
    --level-700: 300;
    --level-600: 200;
    --level-500: 100;
    --level-400: 0;
    --level-300: -100;
    --level-200: -200;
    --level-100: -300;

    /* durations */
    --duration-instant: 0;
    --duration-x-fast: 50ms;
    --duration-fast: 100ms;
    --duration-normal: 200ms;
    --duration-slow: 400ms;
    --duration-x-slow: 800ms;

    /* ease curves*/
    --ease-out: cubic-bezier(0,0,0.6,1);
    --ease-in: cubic-bezier(0.4,0,1,1);
    --ease-in-out: cubic-bezier(0.4,0,0.6,1);
    --linear: cubic-bezier(0,0,1,1);
    --bounce-out: cubic-bezier(0,0,0.6,1.5);
    --bounce-in: cubic-bezier(0.6,-0.5,1,1);
  }
  :root { /* abstraction */
    /* colors */
    --color-primary-25: #fbfcfe;
    --color-primary-50: #ecf2f9;
    --color-primary-100: #d9e6f2;
    --color-primary-200: #b3cce6;
    --color-primary-300: #94b8db;
    --color-primary-400: #6e9ecf;
    --color-primary-500: #4785c2;
    --color-primary-600: #34699d;
    --color-primary-700: #264d73;
    --color-primary-800: #19334d;
    --color-primary-900: #0d1a26;
    --color-secondary-25: #fbfaf9;
    --color-secondary-50: #f3f0ed;
    --color-secondary-100: #e9e3dd;
    --color-secondary-200: #d2c7bc;
    --color-secondary-300: #c2b2a3;
    --color-secondary-400: #38abab;
    --color-secondary-500: #2f8d8d;
    --color-secondary-600: #256f6f;
    --color-secondary-700: #5f3984;
    --color-secondary-800: #3e2556;
    --color-secondary-900: #21142e;
    --color-warning-500: #f43434;
    --color-warning-600: #cb0b0b;
    --color-warning-700: #9b0808;
    --color-attention-100: #f8e8b1;
    --color-attention-200: #f7c55f;
    --color-attention-300: #eda40c;
    --color-success-500: #089608;
    --color-success-600: #067406;
    --color-success-700: #055705;
    --color-info-500: #4785C2;
    --color-info-600: ##34699D;
    --color-info-700: #264d73;
    --color-focus: #d9e6f2;   /* selected/focus, used as outline or background */
    --color-category-1: var(--color-secondary-100);
    --color-category-2: var(--color-secondary-700);
    --color-category-3: var(--color-primary-200);
    --color-category-4: var(--color-secondary-500);
    --color-category-5: var(--color-secondary-300);
    --color-category-6: var(--color-primary-800);
    --color-light: var(--color-white);
    --color-dark: var(--color-gray-800);
    --color-darken: #0000001a;
    --color-lighten: #ffffff33;
    --color-background: var(--color-gray-25);
    --color-border: var(--color-gray-400);

    /* fonts */
    --font-size-100: calc(var(--font-size-200) / var(--type-scale));
    --font-size-200: calc(var(--font-size-300) / var(--type-scale));
    --font-size-300: calc(var(--font-size-400) / var(--type-scale));
    --font-size-400: var(--font-base-size);
    --font-size-500: calc(var(--font-size-400) * var(--type-scale));
    --font-size-600: calc(var(--font-size-500) * var(--type-scale));
    --font-size-700: calc(var(--font-size-600) * var(--type-scale));
    --font-size-800: calc(var(--font-size-700) * var(--type-scale));
    --font-size-900: calc(var(--font-size-800) * var(--type-scale));
    --font-size-1000: calc(var(--font-size-900) * var(--type-scale));
    --font-size-1100: calc(var(--font-size-1000) * var(--type-scale));
    --font-size-1200: calc(var(--font-size-1100) * var(--type-scale));
    --font-size-1300: calc(var(--font-size-1200) * var(--type-scale));
    --font-size-1400: calc(var(--font-size-1300) * var(--type-scale));

    /* sizes & spacing */
    --size-50: var(--base-size);
    --size-100: calc(var(--size-50) * 2);
    --size-200: calc(var(--size-100) * 2);
    --size-300: calc(var(--size-100) * 3);
    --size-400: calc(var(--size-100) * 4);
    --size-500: calc(var(--size-100) * 5);
    --size-600: calc(var(--size-100) * 6);
    --size-700: calc(var(--size-100) * 7);
    --size-800: calc(var(--size-100) * 8);
    --spacing-50: var(--size-50);
    --spacing-100: var(--size-100);
    --spacing-200: var(--size-200);
    --spacing-300: var(--size-300);
    --spacing-400: var(--size-400);
    --spacing-500: var(--size-500);
    --spacing-600: var(--size-600);
    --spacing-700: var(--size-700);
    --spacing-800: var(--size-800);

    /* shadows and eye candy */
    --box-shadow-sharp: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(236, 242, 249) 0px 0px 0px 4px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;

    --max-line-length: 30rem;

  }
  :root { /* elements & components */
    --appbar-height: var(--size-800);
    --appbar-background: var(--color-light);
    --appbar-border-bottom: var(1px solid transparent);
    --banner-border-radius: var(--border-radius-medium);
    --banner-box-shadow: var(--drop-shadow-large);
    --blockquote-border-color: var(--color-primary-500);
    --blockquote-font-size: var(--font-size-300);
    --body-background-color: var(--color-gray-100);
    --body-backgrund-image: var(linearGradient(top to bottom #ffffff, #fafafa));
    --body-font-color: var(--color-dark);
    --body-font-family: var(--font-family-normal);
    --body-font-size: var(--font-size-400);
    --body-font-weight: var(--font-weight-normal);
    --body-line-height: 1.2em;
    --body-line-height-alt: 1.5em;
    --button-background-color: var(--color-gray-800);
    --button-border-color: var(--color-gray-800);
    --button-font-color: var(--color-light);
    --button-border-radius: var(--border-radius-large);
    --button-border-width: var(--border-width-small);
    --button-height: var(--size-500);
    --button-font-letter-spacing: none;
    --button-font-family: var(--font-family-bold);
    --button-font-size: var(--font-size-300);
    --button-font-weight: var(--font-weight-bold);
    --button-text-transform: none;
    --button-background-color-cta: var(--color-primary-700);
    --button-background-color-cta-hover: var(--color-primary-600);
    --button-background-color-cta-active: var(--color-primary-800);
    --button-border-color-cta: var(--color-primary-700);
    --button-border-color-cta-hover: var(--color-primary-600);
    --button-border-color-cta-active: var(--color-primary-800);
    --button-font-color-cta: var(--color-light);
    --button-font-color-cta-hover: var(--color-light);
    --button-font-color-cta-active: var(--color-light);
    --button-background-color: var(--color-gray-800);
    --button-background-color-hover: var(--color-gray-700);
    --button-background-color-active: var(--color-gray-900);
    --button-border-color: var(--color-gray-800);
    --button-border-color-hover: var(--color-gray-700);
    --button-border-color-active: var(--color-gray-900);
    --button-font-color: var(--color-light);
    --button-font-color-hover: var(--color-light);
    --button-font-color-active: var(--color-light);
    --button-background-color-secondary: transparent;
    --button-background-color-secondary-hover: var(--color-gray-700);
    --button-background-color-secondary-active: var(--color-gray-900);
    --button-border-color-secondary: var(--color-gray-800);
    --button-border-color-secondary-hover: var(--color-gray-700);
    --button-border-color-secondary-active: var(--color-gray-900);
    --button-font-color-secondary: var(--color-gray-800);
    --button-font-color-secondary-hover: var(--color-light);
    --button-font-color-secondary-active: var(--color-light);
    --button-background-color-tertiary: transparent;
    --button-background-color-tertiary-hover: var(--color-gray-700);
    --button-background-color-tertiary-active: var(--color-gray-900);
    --button-border-color-tertiary: transparent;
    --button-font-color-tertiary: var(--color-gray-800);
    --button-font-color-tertiary-hover: var(--color-light);
    --button-font-color-tertiary-active: var(--color-light);
    --button-background-color-negative: var(--color-warning-600);
    --button-background-color-negative-hover: var(--color-warning-500);
    --button-background-color-negative-active: var(--color-warning-700);
    --button-border-color-negative: var(--color-warning-600);
    --button-font-color-negative: var(--color-light);
    --button-font-color-negative-hover: var(--color-light);
    --button-font-color-negative-active: var(--color-light);
    --button-background-color-negative-tertiary: transparent;
    --button-background-color-negative-tertiary-hover: var(--color-warning-500);
    --button-background-color-negative-tertiary-active: var(--color-warning-700);
    --button-border-color-negative-tertiary: transparent;
    --button-font-color-negative-tertiary: var(--color-warning-600);
    --button-background-color-disabled: var(--color-gray-100);
    --button-border-color-disabled: var(--color-gray-100);
    --button-font-color-disabled: var(--color-light);
    --heading-font-color: var(--color-dark);
    --heading-font-family: var(--font-family-bold);
    --heading-font-weight: var(--font-weight-bold);
    --heading-line-height: 0.95em;
    --heading-margin: 0.6em 0 0.3em;
    --heading-margin-medium: 0.6em 0;
    --icon-size-small: var(--size-200);
    --icon-size-medium: var(--size-300);
    --icon-size-large: var(--size-400);
    --icon-size-xlarge: var(--size-600);
    --icon-size-small-text: 1.5em;
    --icon-size-medium-text: 1.2em;
    --image-box-border-radius: var(--border-radius-small);
    --input-background-color: var(--color-white);
    --input-background-color-checked: var(--color-primary-600);
    --input-background-color-unchecked: var(--color-gray-300);
    --input-background-color-readonly: var(--color-gray-200);
    --input-background-color-disabled: var(--color-gray-100);
    --input-color: var(--color-gray-600);
    --input-focus-box-shadow: var(--box-shadow-sharp);
    --input-border-color: var(--color-gray-400);
    --input-border-color-checked: var(--color-primary-600);
    --input-border-width: var(--border-width-small);
    --input-border-bottom-width: var(--border-width-medium);
    --input-border-radius: var(--border-radius-small);
    --input-height: var(--size-500);
    --input-placeholder-color: var(--color-gray-600);
    --input-active-color: var(--color-primary-700);
    --input-focus-color: var(--color-focus);
    --input-font-size: var(--font-size-400);
    --label-color: var(--color-gray-700);
    --label-font-size: var(--font-size-300);
    --label-margin: var(--size-100) 0 2px;
    --link-color: var(--color-primary-700);
    --link-color-hover: var(--color-primary-600);
    --link-color-active: var(--color-primary-800);
    --link-decoration: underline;
    --list-dark-border-bottom: var(--border-width-small) solid #ffffff33; /* or rgba(255,255,255,0.2) */
    --list-dark-color: var(--color-light);
    --list-item-info-color: var(--color-primary-500);
    --list-item-background-color: transparent;
    --list-item-background-color-hover: #ffffff33; /* or rgba(255,255,255,0.2) */
    --list-item-background-color-selected: var(--color-primary-100);
    --list-item-border-bottom: var(--border-width-small) solid #0000001a; /* or rgba(0,0,0,0.1) */
    --list-item-border-left: var(--border-widtxh-large) solid transparent;
    --list-item-color: var(--color-dark);
    --ordered-list-margin: 0 0 0.5em;
    --ordered-list-max-width: 45em;
    --paragraph-margin: 0 0 0.5em;
    --paragraph-max-width: 45em;
    --side-menu-shadow: var(--drop-shadow-large);
    --switch-handle-color: var(--color-white);
    --unordered-list-margin: 0 0 0.5em;
    --unordered-list-max-width: 45em;
    --input-elem-max-width: 20rem;
  }
}
