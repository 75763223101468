[data-component="feedback-bar"] {
  padding: var(--spacing-200);
  border-radius: var(--banner-border-radius);
  font-weight: 600;

  &.info {
    background-color: var(--color-info-500);
    border: 1px solid var(--color-info-600);
  }

  &.attention {
    background-color: var(--color-attention-100);
    border: 1px solid var(--color-attention-200);
  }

  &.success {
    background-color: var(--color-success-500);
    border: 1px solid var(--color-success-600);
  }

  &.warning {
    background-color: var(--color-warning-500);
    border: 1px solid var(--color-warning-500);
  }
}
