[data-component="InvoiceList"] {

  > li {

    display: flex;
    flex-direction: column;

    gap: var(--spacing-100);
    padding: var(--spacing-200) 0;

    & + li {
      border-top: 1px solid var(--color-gray-50);
    }

    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: center;

      > h4 {
        flex: 1.2;
      }
    }

    > h4 {

      font-weight: bold;
      font-size: var(--text-size-400);

      display: flex;
      align-items: center;

      > span {
        margin-left: var(--spacing-50);
        color: var(--color-gray-600);
      }
    }

    > dl {

      flex: 1;
      display: flex;

      > div {

        flex-basis: 10rem;

        display: flex;
        flex-direction: column;

        gap: var(--spacing-50);

        > dt {
          color: var(--color-gray-600);
        }

        @media (max-width: 640px) {
          flex: 1;
        }
      }
    }
  }
}
