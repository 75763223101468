/* button.css */
.button {
  @apply border font-bold leading-none transition-all duration-150 text-300;
  border-radius: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  min-width: 4.5em;
  padding: .5rem;
  text-wrap: nowrap;


  &.secondary {
    @apply bg-white text-primary-600 border-primary-600;

    &:hover {
      @apply text-white bg-gray-600 border-gray-600;
    }

    &:focus {
      @apply outline-none ring-4 ring-primary-50;
    }
  }

  &.negative {
    @apply bg-warning-600 text-white border-warning-600;

    &:hover {
      @apply bg-warning-500 border-warning-500;
    }

    &:focus {
      @apply outline-none ring-4 ring-primary-50;
    }
  }

  &.tertiary {
    @apply bg-transparent text-primary-600 border-none;

    &:hover {
      @apply bg-transparent text-primary-600 border-none;
    }
  }

  &.primary {
    @apply bg-gray-700 text-white border-gray-700;

    &:hover {
      @apply bg-gray-600 border-gray-600;
    }

    &:focus {
      @apply bg-gray-700 border-gray-700 outline-none ring-4 ring-primary-50;
    }
  }

  &.cta {
    @apply bg-primary-600 text-white border-primary-600;

    &:hover {
      @apply bg-primary-500 border-primary-500;
    }

    &:focus {
      @apply bg-primary-600 border-primary-600 outline-none ring-4 ring-primary-50;
    }
  }

  &[disabled], &[aria-disabled="true"] {
    @apply bg-gray-300 border-gray-300 text-gray-100;
    pointer-events: none;
  }

  &.back {
    @apply inline-flex items-center py-3.5 px-[1.125rem]
  }

  &.circle {

    border-radius: 100%;
    width: 3em;
    height: 3em;
    min-width: unset;
  }
}
