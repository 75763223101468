[data-component="RecommendationBox"] {

  border: 1px solid var(--color-gray-300);
  background-color: var(--color-primary-50);
  min-height: 100px;
  padding: var(--spacing-50) var(--spacing-200) var(--spacing-50);
  border-radius: var(--border-radius-medium);

  > h4 {

    @apply text-300 font-bold text-primary-500;

    > strong {
      @apply text-400 font-bold text-primary-700;
    }
  }

  .products-list {
    padding: var(--spacing-100) 0;
  }

  .total, .product-row {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-100) 0;
  }

  .total {
    border-top: 1px solid var(--color-gray-300);

    @apply text-400 font-bold text-primary-700;
  }

  .product-row {
    > span > input {
      margin-right: var(--spacing-100);
    }

    > strong {
      @apply text-400 font-bold text-primary-600;
    }
  }
}
