[data-component="CreateInvoiceForm"] {

  .button-submit-container {
    display: flex;
    gap: 1rem;

    flex-direction: row-reverse;

    @media(max-width: 640px) {
      flex-direction: column;
    }
  }
}

.date-container input {
  position: relative;
}

.date-container input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
}
